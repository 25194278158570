import { Document } from '@contentful/rich-text-types';
import AccordionBtn from 'components/commercetools-ui/atoms/accordion';
import RichText from '../../atoms/contentful-rich-text';
import ImageGallery from '../../atoms/image-gallery';
import { ContentImage } from '../../types';

interface ContentSectionProps {
  id?: string;
  title?: string;
  subtitle?: string;
  subtitlePosition?: 'left' | 'top';
  content?: Document;
  tableHeadColor?: string;
  tableHeadTextColor?: string;
  tableBodyColor?: string;
  tableBodyTextColor?: string;
  textContentWidth: '1/4' | '1/3' | '1/2' | '2/3' | '3/4' | 'full';
  images?: ContentImage[];
  imageLayout?: 'inline' | 'grid';
  imagePosition?: 'left' | 'right' | 'centered';
  imageWidth?: '1/4' | '1/3' | '1/2' | 'full';
  className?: string;
  displaySectionAsAccordion?: boolean;
  accordionTitle?: string;
}

const WIDTH_CLASSES = {
  '1/4': 'lg:w-1/4',
  '1/3': 'lg:w-1/3',
  '1/2': 'lg:w-1/2',
  '2/3': 'lg:w-2/3',
  '3/4': 'lg:w-3/4',
  full: 'w-full',
} as const;

const ContentSection: React.FC<ContentSectionProps> = ({
  id,
  title,
  subtitle,
  subtitlePosition = 'top',
  content,
  tableHeadColor,
  tableHeadTextColor,
  tableBodyColor,
  tableBodyTextColor,
  textContentWidth = 'full',
  images = [],
  imageLayout = 'inline',
  imagePosition = 'right',
  imageWidth = 'full',
  className,
  displaySectionAsAccordion = false,
  accordionTitle,
}) => {
  const renderImages = (position: typeof imagePosition) => {
    if (imagePosition !== position || !images.length) return null;

    const imagesContainerClass = `w-full ${position !== 'centered' && 'self-start'} ${
      position !== 'centered' && WIDTH_CLASSES[imageWidth]
    }`;

    return (
      <div className={imagesContainerClass}>
        <ImageGallery images={images} layout={imageLayout} />
      </div>
    );
  };

  const renderContent = () => {
    if (!content) return null;

    if (subtitlePosition === 'left') {
      return (
        <div className="w-full">
          <div className="flex flex-col lg:flex-row lg:gap-16">
            {subtitle && (
              <div className="mb-6 lg:mb-0">
                <p className="text-lg leading-normal">{subtitle}</p>
              </div>
            )}
            <div className={WIDTH_CLASSES[textContentWidth]}>
              <RichText
                content={content}
                className="font-primaryThin"
                tableHeadColor={tableHeadColor}
                tableBodyColor={tableBodyColor}
                tableHeadTextColor={tableHeadTextColor}
                tableBodyTextColor={tableBodyTextColor}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={`w-full ${WIDTH_CLASSES[textContentWidth]}`}>
        {subtitle && <div className="mb-6 text-lg">{subtitle}</div>}
        {title && <h2 className="mb-6 text-4xl leading-normal">{title}</h2>}
        <RichText
          content={content}
          className="font-primaryThin"
          tableHeadColor={tableHeadColor}
          tableBodyColor={tableBodyColor}
          tableHeadTextColor={tableHeadTextColor}
          tableBodyTextColor={tableBodyTextColor}
        />
      </div>
    );
  };

  const mainContent = (
    <div id={id} className={`mb-40 flex flex-col items-center gap-8 lg:flex-row lg:gap-32 ${className}`}>
      {renderImages('left')}
      {renderContent()}
      {renderImages('right')}
      {renderImages('centered')}
    </div>
  );

  if (displaySectionAsAccordion) {
    return (
      <AccordionBtn
        variant="plusAndMinus"
        buttonWrapperClassName="mb-20"
        iconClassName="text-primary-regular"
        closedSectionTitle={accordionTitle || subtitle}
        buttonClassName="font-medium text-16 py-20 px-16 border-b rounded-md"
      >
        {mainContent}
      </AccordionBtn>
    );
  }

  return mainContent;
};

export default ContentSection;
