export const staticPageDataMapper = (data: any) => {
  const fields = data?.data?.dataSource?.items?.[0]?.fields;

  return {
    pageTitle: fields.pageTitle,
    navigationTitle: fields.navigationTitle,
    navigation:
      fields?.navigation?.map((item: any) => ({
        id: item?.fields?.id,
        label: item?.fields?.label,
        href: item?.fields?.href,
      })) || [],
    sections:
      fields?.sections?.map((section: any) => ({
        id: section.fields?.id,
        title: section.fields?.title,
        subtitle: section.fields?.subtitle,
        subtitlePosition: section.fields?.subtitlePosition,
        content: section.fields?.content,
        displaySectionAsAccordion: section.fields?.displaySectionAsAccordion,
        accordionTitle: section.fields?.accordionTitle,
        tableHeadColor: section.fields?.tableHeadColor,
        tableHeadTextColor: section.fields?.tableHeadTextColor,
        tableBodyColor: section.fields?.tableBodyColor,
        tableBodyTextColor: section.fields?.tableBodyTextColor,
        textContentWidth: section.fields?.textContentWidth,
        imageLayout: section.fields.imageLayout,
        images:
          section.fields.images?.map((img: any) => ({
            src: img.fields.file?.fields?.file?.url || '',
            alt: img.fields.description || '',
            link: img.fields.link || '',
            imageWidth: img.fields.imageWidth || '',
          })) || [],
        imagePosition: section.fields?.imagePosition,
        imageWidth: section.fields?.imageWidth,
      })) || [],
  };
};
