import { useEffect, useState } from 'react';
import SideMenu from '../../atoms/side-menu';
import { ContentImage } from '../../types';
import ContentSection from '../content-section';

export interface StaticSection {
  id: string;
  title?: string;
  subtitle?: string;
  subtitlePosition?: string;
  content?: Document;
  displaySectionAsAccordion?: boolean;
  accordionTitle?: string;
  tableHeadColor?: string;
  tableHeadTextColor?: string;
  tableBodyColor?: string;
  tableBodyTextColor?: string;
  textContentWidth?: string;
  images?: ContentImage[];
  imageLayout?: 'inline' | 'grid';
  image?: string;
  imageSrc?: string;
  imageAlt?: string;
  imagePosition?: 'left' | 'right';
  imageWidth?: '1/2' | '1/3' | '1/4' | '2/3' | '3/4' | 'full';
}

export interface MenuItem {
  id: string;
  label: string;
  href: string;
}

export interface StaticPageData {
  pageTitle?: string;
  navigationTitle: string;
  navigation: MenuItem[];
  sections: StaticSection[];
}

interface StaticPageContentProps {
  initialData: StaticPageData;
}

const StaticPageContent: React.FC<StaticPageContentProps> = ({ initialData }) => {
  const [activeSection, setActiveSection] = useState<string>(initialData.navigation[0]?.id || '');

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('[data-section]');

      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= 150 && rect.bottom >= 150) {
          setActiveSection(section.id);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (!initialData?.sections?.length) {
    return <div>No content available</div>;
  }

  return (
    <div className="grid grid-cols-1 md:gap-52 lg:grid-cols-8">
      {initialData?.navigation?.length > 0 && (
        <SideMenu
          items={initialData.navigation}
          navigationTitle={initialData.navigationTitle}
          activeId={activeSection}
        />
      )}

      <div className={`my-32 ${initialData?.navigation?.length ? 'lg:col-span-6' : 'lg:col-span-8'}`}>
        {initialData?.pageTitle && (
          <p className="pb-5 text-left font-body text-28 leading-normal" id="sizeGuides">
            {initialData?.pageTitle}
          </p>
        )}
        {initialData.sections.map((section: any) => {
          return (
            <div key={section.id} data-section id={section.id}>
              <ContentSection
                title={section.title}
                subtitle={section.subtitle}
                subtitlePosition={section.subtitlePosition}
                content={section.content || ''}
                displaySectionAsAccordion={section.displaySectionAsAccordion}
                accordionTitle={section.accordionTitle}
                tableHeadColor={section.tableHeadColor}
                tableHeadTextColor={section.tableHeadTextColor}
                tableBodyColor={section.tableBodyColor}
                tableBodyTextColor={section.tableBodyTextColor}
                textContentWidth={section.textContentWidth}
                images={section.images}
                imageLayout={section.imageLayout}
                imagePosition={section.imagePosition}
                imageWidth={section.imageWidth}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StaticPageContent;
